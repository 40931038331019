import { useSelector } from 'react-redux';
import { useState } from 'react';
import { isWithinHoursLimit } from '../multiday/utils';
import { useRate } from './useRate';

export const useTipsCard = (job) => {
  const { localStart, rate, localDate, profession } = job;
  const [isFlipped, setIsFlipped] = useState(false);
  const averageRating = useSelector((state) => state.job.averageRating) || {
    average: 49,
    max: 100,
    min: 18,
  };
  const profRate = averageRating[profession];
  const { industrialAverage } = useRate({ averagePrice: profRate, defaultValue: rate });
  const showTips = isWithinHoursLimit(localDate, localStart, 36) && industrialAverage <= 105;

  const handleTipsClick = () => {
    setIsFlipped(!isFlipped);
  };

  return {
    isFlipped,
    handleTipsClick,
    showTips,
  };
};
