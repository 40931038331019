import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ADD_FAVORITE_PROFESSIONAL,
  BLOCK_RECENT_PROFESSIONAL,
  FETCH_OPEN_JOBS_FOR_RECENT_PROFESSIONAL_ACTION,
} from '../../actions';
import { clearSuccess } from '../../actions/success';
import { createSuccessSelector } from '../../api/selectors';
import { Tab, TabList, TabPanel, Tabs } from '../../commonComponents/Tabs';
import history from '../../history';
import ChevronRight from '../../images/arrow_right.svg';
import MailSvg from '../../images/mail.svg';
import { Colors } from '../../themes/colors';
import ConfirmPopup from '../shared/ConfirmPopup';
import { FavoritesList } from './components/FavoritesList/FavoritesList';
import { FilterSelect } from './components/FilterSelect/FilterSelect';
import { HighlyRatedList } from './components/HighlyRatedList/HighlyRatedList';
import { NewProfessionalsHubPopup } from './components/NewProfessionalsHubPopup';
import { RecentProfessionalsList } from './components/RecentProfessionalsList/RecentProfessionalsList';
import { TABS } from './utils';

const successAddToFavoritesSelector = createSuccessSelector([ADD_FAVORITE_PROFESSIONAL]);
const successBlockProfessionalSelector = createSuccessSelector([BLOCK_RECENT_PROFESSIONAL]);
const successFetchJobsSelector = createSuccessSelector([
  FETCH_OPEN_JOBS_FOR_RECENT_PROFESSIONAL_ACTION,
]);

export const getInitialTab = (type) => {
  if (type === TABS.HIGHLY_RATED) return 1;
  if (type === TABS.RECENT_PROFESSIONALS) return 2;
  return 0;
};

export const ProfessionalsHub = ({ match }) => {
  const { type } = match.params;
  const dispatch = useDispatch();
  const activeTab = getInitialTab(type);
  const isAddedToFavoritesSuccess = useSelector((state) => successAddToFavoritesSelector(state));
  const isBlockedProfessionalSuccess = useSelector((state) =>
    successBlockProfessionalSelector(state),
  );
  const [showSuccessAddedToFavoritesModal, setShowSuccessAddedToFavoritesModal] = useState(false);
  const [showSuccessBlockProfessionalModal, setShowSuccessBlockProfessionalModal] = useState(false);
  const isSuccessFetchJobsRequest = useSelector((state) => successFetchJobsSelector(state));
  const { offers } = useSelector((state) => state.job.recentOpenJobs);
  const [showEmptyShiftsModal, setShowEmptyShiftsModal] = useState(false);
  const firstRenderRef = useRef(true);

  const onChangeTab = useCallback((tab) => {
    let selectedTab = TABS.FAVORITES_LIST;
    switch (tab) {
    case 1:
      selectedTab = TABS.HIGHLY_RATED;
      break;
    case 2:
      selectedTab = TABS.RECENT_PROFESSIONALS;
      break;
    default:
      break;
    }
    history.push(`/professionals-hub/${selectedTab}`);
  }, []);

  useEffect(() => {
    if (!firstRenderRef.current) {
      if (isAddedToFavoritesSuccess) {
        setShowSuccessAddedToFavoritesModal(true);
        dispatch(clearSuccess(ADD_FAVORITE_PROFESSIONAL));
      }

      if (isBlockedProfessionalSuccess) {
        setShowSuccessBlockProfessionalModal(true);
        dispatch(clearSuccess(BLOCK_RECENT_PROFESSIONAL));
      }
    } else {
      dispatch(clearSuccess([ADD_FAVORITE_PROFESSIONAL, BLOCK_RECENT_PROFESSIONAL]));
      firstRenderRef.current = false;
    }
  }, [isAddedToFavoritesSuccess, isBlockedProfessionalSuccess, dispatch]);

  useEffect(() => {
    if (isSuccessFetchJobsRequest) {
      if (!offers?.length) {
        setShowEmptyShiftsModal(true);
      }
    }
  }, [isSuccessFetchJobsRequest, dispatch, offers]);

  return (
    <div className="component_container">
      <div
        className="floating_container"
        style={{
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          padding: 40,
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
          }}
        >
          <h1 className="global_font f-dark" style={{ alignSelf: 'center' }}>
            Professionals Hub
          </h1>

          <div
            style={{
              width: '80%',
              marginLeft: 80,
              marginTop: 20,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <FilterSelect />
            <button
              type="button"
              style={{
                all: 'unset',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 8,
                cursor: 'pointer',
              }}
              onClick={() => history.push('/invites')}
            >
              <img src={MailSvg} alt="mail" style={{ width: 24, height: 24, marginLeft: 10 }} />
              <b
                style={{
                  fontFamily: 'Nunito',
                  color: Colors.secondary_500,
                  fontSize: 18,
                  cursor: 'pointer',
                }}
              >
                View Invites
              </b>
              <img src={ChevronRight} alt="arrow" style={{ width: 16, height: 16 }} />
            </button>
          </div>

          <div style={{ width: '80%', alignSelf: 'center', marginTop: 40 }}>
            <Tabs initialTab={activeTab} onChangeTab={onChangeTab}>
              <TabList>
                <Tab title="Favorites List" />
                <Tab title={<TabTitle />} />
                <Tab title="Recent Professionals" />
              </TabList>
              <TabPanel>
                <FavoritesList />
                <HighlyRatedList />
                <RecentProfessionalsList />
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
      <NewProfessionalsHubPopup />

      {showSuccessAddedToFavoritesModal && (
        <ConfirmPopup
          title="New Favorite Added"
          description={
            <>
              This professional has been added onto your
              <br />
              favorites list tab.
            </>
          }
          descriptionStyle={{ marginBottom: 60 }}
          rightButtonText="Close"
          rightButtonAction={() => setShowSuccessAddedToFavoritesModal(false)}
        />
      )}

      {showSuccessBlockProfessionalModal && (
        <ConfirmPopup
          title="Blocked Professional"
          description={
            <>
              This professional has been blocked and will not be able to apply to upcoming shifts.
            </>
          }
          descriptionStyle={{ marginBottom: 60 }}
          rightButtonText="Close"
          rightButtonAction={() => setShowSuccessBlockProfessionalModal(false)}
        />
      )}

      {showEmptyShiftsModal && (
        <ConfirmPopup
          title="No Upcoming Shifts"
          description={
            <div
              style={{
                textAlign: 'left',
              }}
            >
              Looks like there are no shifts that can be sent to this professional. This may happen
              because either:
              <br />
              <br />
              <ul>
                <li>Your office does not have any shifts posted.</li>
                <li>
                  This professional is unavailable or already booked for the dates you need
                  coverage.
                </li>
              </ul>
            </div>
          }
          descriptionStyle={{ marginBottom: 60 }}
          rightButtonText="Close"
          rightButtonAction={() => setShowEmptyShiftsModal(false)}
        />
      )}
    </div>
  );
};

const TabTitle = () => {
  const user = useSelector((state) => state.user.selectedChildOffice ?? state.user.user);
  const [isHighlyRatedTabNew, setIsHighlyRatedTabNew] = useState(
    localStorage.getItem(`isProfessionalHubNew-${user?.id}`) &&
      !localStorage.getItem(`isHighlyRatedTabNew-${user.id}`),
  );

  useEffect(() => {
    const handleStorageChange = () => {
      const newValue = localStorage.getItem(`isHighlyRatedTabNew-${user.id}`);
      setIsHighlyRatedTabNew(!newValue);
    };

    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [user.id]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 8,
      }}
    >
      Highly-Rated
      {isHighlyRatedTabNew && (
        <div
          style={{
            fontFamily: 'Nunito',
            fontSize: 12,
            fontWeight: 700,
            backgroundColor: Colors.secondary_500,
            color: Colors.white,
            width: 48,
            height: 20,
            borderRadius: 3,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          NEW
        </div>
      )}
    </div>
  );
};
