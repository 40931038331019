import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserEducation, getUserOvertimeLimits } from '../../../actions';
import { UserProfession, UserSubProfession } from '../../../enums/UserProfession';
import { getShouldOfficeShowEfda } from '../../../growthbook';
import history from '../../../history';
import { getAllProfessionTypeOptions } from '../../../utils/DentistUtils';
import { EnumHelper } from '../../../utils/EnumHelper';

export const useShiftDetailsStep = (job) => {
  const dispatch = useDispatch();
  const professional = history?.location?.state?.professional || { id: '', name: '' };
  const selectedChildOffice = useSelector((state) => state.user.selectedChildOffice);
  const initialValues = {
    offerStartTime: job?.localStart || null,
    offerEndTime: job?.localEnd || null,
    rate: job?.rate || 0,
    lunch: job?.break_time === 0 ? '0 min' : job?.break_time || null,
    isLunchPaid: job?.is_break_paid || false,
    professionType:
      UserSubProfession.getName(job?.subProfession) ?? UserProfession.getName(job?.profession),
    skills: job?.specialty || 'specialty_General Dentistry',
    procedures: job?.procedures ?? [],
    enableAutoFill: job?.autoFill || false,
    enableAutoFillFavorites: job?.autoFillFavorites || false,
    enableAutoFillHighlyRated: job?.autoFillHighlyRated || false,
    isAssistedHygiene: job?.isAssistedHygiene || selectedChildOffice.office_info.isAssistedHygiene,
    professional,
  };
  const [formValues, setFormValues] = useState(initialValues);
  const educationInfo = selectedChildOffice?.office_info?.education;
  const defaultSpecialty =
    Object.keys(educationInfo?.specialtiesAndProcedures || {})[0] || 'specialty_General Dentistry';

  const selectedProfessionCode = useSelector((state) => {
    const { allProfessions } = state.user;
    const showEfda = getShouldOfficeShowEfda();
    const allProfessionOptions = getAllProfessionTypeOptions(allProfessions, { showEfda });

    return allProfessionOptions?.filter((item) => item.name === formValues.professionType)?.[0]
      ?.code;
  });

  const isDisabled =
    !formValues.offerStartTime ||
    !formValues.offerEndTime ||
    formValues.rate === 0 ||
    !formValues.lunch ||
    (selectedProfessionCode === UserProfession.RDH && formValues.isAssistedHygiene === undefined);

  const jobData = {
    localStart: formValues.offerStartTime,
    localEnd: formValues.offerEndTime,
    rate: formValues.rate,
    is_break_paid: formValues.isLunchPaid,
    break_time: formValues.lunch,
    profession: UserProfession.getNormalizedCode(selectedProfessionCode),
    subProfession: EnumHelper.getObjectKeys(UserSubProfession).includes(selectedProfessionCode)
      ? selectedProfessionCode
      : null,
    specialty: formValues.skills || 'specialty_General Dentistry',
    autoFill: formValues.enableAutoFill,
    autoFillFavorites: formValues.enableAutoFillFavorites,
    autoFillHighlyRated: formValues.enableAutoFillHighlyRated,
    procedures: formValues.procedures,
    ...(selectedProfessionCode === UserProfession.RDH && {
      isAssistedHygiene: formValues.isAssistedHygiene,
    }),
    professional: formValues.professional,
  };

  useEffect(() => {
    setFormValues((prevState) => ({
      ...prevState,
      skills: defaultSpecialty,
      procedures: [],
      enableAutoFill: false,
      enableAutoFillFavorites: false,
      enableAutoFillHighlyRated: false,
    }));

    const professionCode = UserProfession.getNormalizedCode(
      UserProfession.getCode(formValues.professionType),
    );

    dispatch(getUserEducation({ profession: professionCode }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.professionType]);

  useEffect(() => {
    setFormValues((prevState) => ({ ...prevState, procedures: [] }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.skills]);

  useEffect(() => {
    if (!selectedChildOffice?.overtimeLimits) {
      dispatch(getUserOvertimeLimits());
    }
  }, [dispatch, selectedChildOffice?.overtimeLimits]);

  return { formValues, setFormValues, isDisabled, jobData };
};
