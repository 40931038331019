import { Colors } from '../themes/colors';

export const Text = ({
  children,
  style,
  marginRight,
  marginLeft,
  marginBottom,
  marginTop,
  width,
  color = Colors.neutral_600,
  fontSize = 16,
  bold = false,
  textAlign = 'left',
  ...props
}) => (
  <span
    {...props}
    style={{
      fontFamily: 'Nunito',
      fontWeight: bold ? 700 : 500,
      fontSize,
      width,
      color,
      textAlign,
      marginRight,
      marginLeft,
      marginBottom,
      marginTop,
      ...style,
    }}
  >
    {children}
  </span>
);
