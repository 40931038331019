import BackButton from '../../../commonComponents/BackButton';
import { Colors } from '../../../themes/colors';
import RebookEmptySVG from '../../../images/rebook-empty.svg';

export const RebookEmptyScreen = () => (
  <div className="component_container">
    <div
      className="floating_container"
      style={{
        justifyContent: 'flex-start',
        flexDirection: 'column',
        fontFamily: 'Nunito',
      }}
    >
      <div className="card">
        <div style={{ marginTop: 20, marginLeft: 30 }}>
          <BackButton />
        </div>

        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            marginTop: -32,
          }}
        >
          <b
            style={{
              margin: 'auto',
              color: Colors.neutral_600,
              textAlign: 'center',
              fontSize: 32,
            }}
          >
            Re-Book Request
          </b>

          <img src={RebookEmptySVG} alt="Rebook Empty" style={{ margin: 'auto', marginTop: 32 }} />
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 24,
            marginTop: 32,
            marginLeft: 60,
            marginRight: 60,
            marginBottom: 100,
            color: Colors.neutral_600,
            fontSize: 16,
          }}
        >
          <b
            style={{
              color: Colors.primary_500,
              fontSize: 20,
            }}
          >
            Whoops!
          </b>

          <span>
            It looks like you do not have any professionals to re-book. Please return to the
            dashboard and post a new shift using the “Request A Temp” button.
          </span>

          <span>
            Once you have worked with a professional, you can use this page to create new shifts &
            invite a specific professional you want to re-book for these shifts.
          </span>

          <div>
            <span>What you need to know about re-booking:</span>

            <ul>
              <li>
                This re-booking request is not a guarantee that the professional will work the
                shift. They will receive an invite and can choose to accept, decline, or submit a
                counter offer.
              </li>
              <br />

              <li>
                Some invites may not be delivered. Professionals will not receive your invite(s) for
                days that their availability is turned off.
              </li>
              <br />

              <li>
                If the professional accepts the invite at the listed rate, they will be
                auto-confirmed for the shift.
              </li>
              <br />

              <li>
                If the professional submits a counter-offer, their application will be sent to the
                standard pool.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
);
