import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { createLoadingSelector } from '../../api/selectors';
import CustomSpinner from '../../commonComponents/CustomSpinner';
import { currencyFormat } from '../../hooks/currencyFormat';
import arrowBack from '../../images/backButtonBlueIcon.svg';
import { Colors } from '../../themes/colors';

const titleStyle = {
  color: Colors.neutral_600,
  fontFamily: 'Nunito',
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '27px',
  marginBottom: '17px',
  textAlign: 'center',
};
const subtitleStyle = {
  fontFamily: 'Nunito',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '16px',
  lineHeight: '25px',
  color: Colors.neutral_600,
};

const normalText = {
  fontFamily: 'Nunito',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '16px',
  lineHeight: '22px',
  color: Colors.neutral_500,
};

const Card = ({ children }) => (
  <div
    className="dashboard-billing-shadow"
    style={{
      height: 198,
      paddingTop: 30,
      paddingBottom: 30,
    }}
  >
    {children}
  </div>
);

const BillingWidgetCard = (params) => {
  if (params.isLoading) {
    return (
      <Card>
        <CustomSpinner
          style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          isLoading={params.isLoading}
        />
      </Card>
    );
  }

  return (
    <Card>
      {params.data ? (
        <div
          style={{
            textDecoration: 'none',
            flexShrink: 1,
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
            scrollbarWidth: 'thin',
          }}
        >
          <span style={titleStyle}>Billing</span>
          <span style={subtitleStyle}>Last Receipts</span>

          <Link
            to={`/dashboard/${params.type}`}
            style={{
              textDecoration: 'none',
              flexShrink: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <span style={normalText}>{`${params.data.offer_owner?.office_name}`}</span>
            <span style={normalText}>
              {`Shift on ${moment(params.data.jobDate).format('MM/DD/YYYY hh:mm A')}`}
            </span>
            <span style={normalText}>
              {`Charged on ${moment(params.data.date).format('MM/DD/YYYY hh:mm A')}`}
            </span>
            <span style={normalText}>
              <span style={subtitleStyle}>Total Pay: </span>
              {params.data.type === 'credit'
                ? `- $${currencyFormat(params.data.amount?.toFixed(2))}`
                : `$${currencyFormat(params.data.amount?.toFixed(2))}`}
            </span>
          </Link>

          <Link
            to="/dashboard/receipts"
            style={{
              textDecoration: 'none',
              marginTop: 12,
              fontFamily: 'Nunito',
              fontStyle: 'normal',
              fontWeight: '700',
              fontSize: 18,
              color: Colors.secondary_500,
              alignSelf: 'end',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            View all Receipts
            <img
              src={arrowBack}
              alt="back"
              style={{
                height: 14,
                marginRight: 20,
                marginLeft: 14,
                rotate: '180deg',
              }}
            />
          </Link>
        </div>
      ) : (
        <span style={titleStyle}>No receipts</span>
      )}
    </Card>
  );
};

const BillingCard = ({ lastReceipt }) => {
  const loadingSelector = createLoadingSelector(['FETCH_DASHBOARD_INFO']);
  const isLoading = useSelector((state) => loadingSelector(state));

  return <BillingWidgetCard data={lastReceipt} type="receipts" isLoading={isLoading} />;
};

export default BillingCard;
