import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import activeStep from '../../../images/progressBarMDB/activeStep.svg';
import firstStep from '../../../images/progressBarMDB/greenSolidCircle.svg';
import inactiveStep from '../../../images/progressBarMDB/inactiveStep.svg';
import { Colors } from '../../../themes/colors';
import browserHistory from '../../../history';

const Step = ({ title, active, isFirstStep }) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
    }}
  >
    {isFirstStep && <img src={firstStep} alt={title} />}
    {!isFirstStep && active && <img src={activeStep} alt={title} />}
    {!isFirstStep && !active && <img src={inactiveStep} alt={title} />}
    <span
      style={{
        marginLeft: 4,
        color: active ? Colors.neutral_600 : Colors.neutral_300,
        fontFamily: 'Nunito',
      }}
    >
      {title}
    </span>
  </div>
);

export default ({ step, title = 'Temp Request', redirectUrl = '/dashboard/multi-day/info' }) => {
  const jobDates = useSelector((state) => state.multiday.jobDates);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (jobDates === null && step > 1) {
      browserHistory.replace(redirectUrl);
    }
  }, [step, redirectUrl, jobDates]);

  return (
    <div>
      <h1
        className="card-title global_font f-dark"
        style={{ fontFamily: 'Nunito', textAlign: 'center' }}
      >
        {title}
      </h1>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: 30,
          gap: '4px',
          marginTop: 24,
        }}
      >
        <Step title="Shift Details" active isFirstStep />
        <Step title="Review" active={step >= 2} />
        <Step title="Guidelines & Confirmation" active={step >= 3} />
      </div>
    </div>
  );
};
