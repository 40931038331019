import { useParams } from 'react-router-dom';
import BackButton from '../../../commonComponents/BackButton';
import Button from '../../../commonComponents/Button';
import CardSpinner from '../../../commonComponents/CardSpinner';
import Form, { FORM_FIELDS } from '../multiday/Form';
import { useEditJobForm } from './hooks/useEditJobForm/useEditJobForm';
import { useJob } from './hooks/useJob/useJob';

export const EditOpenJob = () => {
  const { jobId } = useParams();
  const { job, isLoading: isLoadingJob } = useJob(jobId);
  const { formValues, handleSubmit, setFormValues, isLoading: isSaving } = useEditJobForm(job);

  if (isLoadingJob || !formValues) {
    return <CardSpinner />;
  }

  return (
    <div className="component_container">
      <div
        className="floating_container"
        style={{ justifyContent: 'flex-start', flexDirection: 'column', paddingBottom: 120 }}
      >
        <div className="card">
          <div className="card-body" style={{ margin: 30 }}>
            <BackButton />
            <h1
              className="card-title global_font f-dark"
              style={{ fontFamily: 'Nunito', textAlign: 'center', marginBottom: 60 }}
            >
              Edit Shift
            </h1>
            <Form
              job={job}
              formValues={formValues}
              setFormValues={setFormValues}
              disabledFields={[FORM_FIELDS.PROFESSIONAL, FORM_FIELDS.SKILLS]}
              editDate
            />
          </div>
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: 20,
            marginTop: 20,
          }}
        >
          <Button text="Save Changes" onClick={handleSubmit} isLoading={isSaving} />
        </div>
      </div>
    </div>
  );
};
