import { useDispatch, useSelector } from 'react-redux';
import {
  FETCH_FAVORITE_PROFESSIONALS,
  FETCH_RECENT_PROFESSIONALS,
  GET_USER_OVERTIME_LIMITS_ACTION,
  SELECT_DEFAULT_INFO_MDB,
  selectDefaultInfoMDB,
} from '../../actions';
import BackButton from '../../commonComponents/BackButton';
import Button from '../../commonComponents/Button';
import CardSpinner from '../../commonComponents/CardSpinner';
import history from '../../history';
import useLoading from '../../hooks/useLoading';
import { Colors } from '../../themes/colors';
import { useShiftDetailsStep } from './hooks/useShiftDetailsStep';
import EditShiftMDB from './multiday/EditShiftMDB';
import Form from './multiday/Form';
import ProgressBar from './multiday/ProgressBar';
import { RebookEmptyScreen } from './rebook/RebookEmptyScreen';

const actions = [
  SELECT_DEFAULT_INFO_MDB,
  FETCH_FAVORITE_PROFESSIONALS,
  FETCH_RECENT_PROFESSIONALS,
  GET_USER_OVERTIME_LIMITS_ACTION,
];

export function ShiftDetailsContent({ showInvite, title, urlPrefix, description }) {
  const editedDate = history.location?.state?.editedDate || '';
  const currentJob = useSelector(
    (state) => state.multiday.jobInfo.filter((job) => job.localDate === editedDate)[0],
  );
  const { formValues, setFormValues, jobData, isDisabled } = useShiftDetailsStep(currentJob);
  const { isLoading } = useLoading(actions);
  const dispatch = useDispatch();
  const jobDates = useSelector((state) => state.multiday.jobDates);
  const favoriteProfessionals = useSelector((state) => state.user.favoriteProfessionals);
  const recentProfessionals = useSelector((state) => state.user.recentProfessionals);

  const isButtonDisabled =
    isDisabled || !jobDates?.length || (showInvite && !formValues.professional?.id);

  const handleNextButton = () => {
    dispatch(selectDefaultInfoMDB({ jobData }));
    history.push(`${urlPrefix}/confirm`);
  };

  if (isLoading) {
    return <CardSpinner />;
  }

  if (!isLoading && !favoriteProfessionals?.length && !recentProfessionals?.length) {
    return <RebookEmptyScreen />;
  }

  if (editedDate && currentJob) {
    return <EditShiftMDB currentJob={currentJob} showInvite />;
  }

  return (
    <div className="component_container">
      <div
        className="floating_container"
        style={{ justifyContent: 'flex-start', flexDirection: 'column' }}
      >
        <div className="card">
          <div style={{ marginTop: 20, marginLeft: 30 }}>
            <BackButton />
          </div>
          <div className="card-body" style={{ margin: 30 }}>
            <ProgressBar step={1} title={title} redirectUrl={`${urlPrefix}/info`} />

            {description && (
              <div
                style={{
                  margin: 'auto',
                  marginTop: 24,
                  color: Colors.neutral_500,
                  fontFamily: 'Nunito',
                  textAlign: 'center',
                  fontSize: 16,
                  fontStyle: 'italic',
                  width: 748,
                }}
              >
                {description}
              </div>
            )}

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                marginTop: 48,
              }}
            >
              <Form formValues={formValues} setFormValues={setFormValues} showInvite />
            </div>
          </div>
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: 120,
            marginTop: 20,
          }}
        >
          <Button text="Next" onClick={handleNextButton} disabled={isButtonDisabled} />
        </div>
      </div>
    </div>
  );
}
