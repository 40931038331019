import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchFavoriteProfessionals,
  fetchHighlyRatedProfessionals,
  fetchRecentProfessionals,
} from '../../../actions';
import { ShiftDetailsContent } from '../ShiftDetailsContent';

export function RebookShiftDetails() {
  const dispatch = useDispatch();
  const selectedChildOffice = useSelector((state) => state.user.selectedChildOffice);

  useEffect(() => {
    dispatch(fetchFavoriteProfessionals());
    dispatch(fetchRecentProfessionals());
    dispatch(fetchHighlyRatedProfessionals({ page: 1, limit: 20 }));
  }, [dispatch, selectedChildOffice]);

  return (
    <ShiftDetailsContent
      title="Re-Book Request"
      description="Get re-booked with 1 professional you enjoyed working with! These shifts will also be posted on GoTu’s public marketplace, as invites will not be delivered to professionals on dates that they are unavailable."
      urlPrefix="/dashboard/re-book"
      showInvite
    />
  );
}
