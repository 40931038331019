import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent, setUserProperties, setUserId } from 'firebase/analytics';
import * as Sentry from '@sentry/react';
import history from '../history';
import { store } from '../store/configureStore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY_ANALYTICS,
  authDomain: 'dental-portal-2939f.firebaseapp.com',
  projectId: 'dental-portal-2939f',
  storageBucket: 'dental-portal-2939f.appspot.com',
  messagingSenderId: '876996125452',
  appId: '1:876996125452:web:19b1d47d2ec8528c361f29',
  measurementId: 'G-48PX82CL20',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig, 'analytics');
const analytics = getAnalytics(app);

export const logCustomEvent = async (eventName, eventParams) => {
  if (process.env.REACT_APP_TEMP_MEE_ENV === 'production') {
    await logEvent(analytics, 'page_view', {
      page_location: history.location.pathname,
      page_path: history.location.pathname,
      page_title: history.location.pathname,
    });
    await logEvent(analytics, eventName, eventParams);
  }
};

export const custoSetUserProperties = async (user) => {
  if (process.env.REACT_APP_TEMP_MEE_ENV === 'production') {
    const data = {
      name: `${user?.first_name || ''} ${user?.last_name}`,
      email: user?.email_id || '',
      phone_number: user?.contact_number,
      office: user?.office_name,
    };
    await setUserId(analytics, user?.id);

    await setUserProperties(analytics, data);
  }
};

export const trackEvent = (eventName, eventParams) => {
  try {
    const user = store?.getState()?.user?.user;

    const commonProperties = {};
    if (user) {
      const address = user?.address?.[0] || {};

      Object.assign(commonProperties, {
        userId: user?.id,
        userType: user?.user_type,
        city: address?.city,
        state: address?.state,
        email: user?.email_id,
        title: user?.title,
        firstName: user?.first_name,
        lastName: user?.last_name,
        additionalContacts: user?.account_admin,
        officeType: user?.office_info?.officeType,
        isParentOffice: !!user?.childOffices?.length,
      });
    }

    window.analytics.track(eventName, {
      dateTime: new Date().toISOString(),
      browser: window?.navigator?.userAgent,
      ...commonProperties,
      ...eventParams,
    });
  } catch (error) {
    // eslint-disable-next-line max-len
    const errorMessage = `An error occurred while tracking event: ${eventName}, error: ${error.message}`;
    Sentry.captureException(new Error(errorMessage));
  }
};
