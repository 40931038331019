import React from 'react';
import { Link } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { Text } from '../../commonComponents';
import { Colors } from '../../themes/colors';

/** Clickable div with a title on the top and a big number below */
const NumberItemWithTitle = ({ title, value, clickUrl, highlightCount }) => {
  const highlighted = highlightCount && value > 0;

  return (
    <Link
      style={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'Nunito',
        justifyContent: 'space-evenly',
        textDecoration: 'none',
        gap: 8
      }}
      to={clickUrl}
    >
      <div
        style={{
          alignItems: 'center',
          backgroundColor: highlighted ? Colors.error_500 : Colors.secondary_500,
          borderRadius: '20px',
          boxShadow: '0 8px 12px 2px #00000026, 0 2px 4px 0px #00000026',
          color: highlighted ? Colors.white : Colors.neutral_50,
          display: 'flex',
          fontSize: '46px',
          fontWeight: 700,
          height: '95px',
          justifyContent: 'center',
          lineHeight: '62px',
          width: '97px',
        }}
      >
        {value}
      </div>
      <Text>{title}</Text>
    </Link>
  );
};

const ShiftsWidget = ({ activeCount, scheduledCount, actionRequiredCount }) => (
  <Link
    style={{
      fontFamily: 'Nunito',
      textDecoration: 'none',
      flex: 1,
    }}
    to="/dashboard/jobs/open"
  >
    <div 
      className="dashboard-shifts-shadow"
      style={{
        flexDirection: 'column',
        height: 198,
        paddingBottom: 30,
        paddingTop: 30,
      }}
    >
      {!activeCount && !scheduledCount && !actionRequiredCount ? (
        <div
          style={{
            height: '258',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            gap: 20,
          }}
        >
          <ClipLoader sizeUnit="px" size={50} color={Colors.primary_500} loading />
        </div>
      ) : (
        <>
          <Text
            bold
            fontSize={24}
            textAlign='center'
            style={{
              fontStyle: 'normal',
              lineHeight: '27px',
              marginBottom: '17px',
            }}
          >
            Shift Overview
          </Text>
          <div 
            style={{
              display: 'flex',
              justifyContent: 'space-evenly',
              width: '100%',
            }}
          >
            <NumberItemWithTitle
              title="Open Shifts"
              value={activeCount}
              clickUrl="/dashboard/jobs/open"
            />
            <NumberItemWithTitle
              title="Action Needed"
              value={actionRequiredCount}
              clickUrl="/dashboard/jobs/pending"
              highlightCount
            />
            <NumberItemWithTitle
              title="Scheduled"
              value={scheduledCount}
              clickUrl="/dashboard/jobs/scheduled"
            />
          </div>
        </>
      )}
    </div>
  </Link>
);

export default ShiftsWidget;
