/* eslint-disable no-case-declarations */
import moment from 'moment';
import { apiMethodsConst } from '../services/methods';
import { Colors } from '../themes/colors';
import * as actions from '../actions';

export const MAX_LIMIT_PROFESSIONAL_INVITES = 10;

const initialState = {
  selectedCalendarTab: '',
  jobsOnCalendarStyle: {},
  selectedDaysOnCalendar: {},
  jobsPerDay: {},
  jobList: [],
  jobListPagination: {
    page: 1,
    limit: 10,
    status: null,
    isFinal: false,
    isActiveRequest: false,
  },
  dashboardJobListPagination: {
    page: 1,
    limit: 10,
    isFinal: false,
    isActiveRequest: false,
  },
  jobsWithTransaction: [],
  jobsWithTransactionPagination: {
    page: 1,
    limit: 10,
    isFinal: false,
  },
  job: null,
  isOverlappingJob: false,
  message: '',
  pendingCount: 0,
  openJobs: [],
  scheduledJobs: [],
  recentOpenJobs: {
    professionalId: null,
    page: 1,
    total: null,
    limit: MAX_LIMIT_PROFESSIONAL_INVITES || 10,
    isFinal: false,
    isActiveRequest: false,
    offers: [],
  },
  actionRequiredJobs: [],
  todayJobs: [],
  expiredCounteOffers: [],
  receipts: [],
  receiptsPagination: {
    page: 1,
  },
  receiptDisplayMode: 'shift',
  totalReceipts: 0,
  isActiveRequest: false,
  isFinalPage: false,
  receiptReport: '',
  averageRating: {
    DN: { min: 18, max: 180, average: 89 },
    DA: { min: 18, max: 50, average: 23 },
    RDH: { average: 49, max: 100, min: 18 },
  },
  adjustmentsSend: [],
  // invoices
  invoices: [],
  invoicesPagination: {
    page: 1,
  },
  totalInvoices: 0,
  totalUnpaid: {
    count: 0,
    sum: 0,
  },
  isActiveRequestInvoices: false,
  isFinalPageInvoices: false,
  adjustments: [],
  cancelationReasons: [],
  showReportIncidentSuccessPopup: null,
  showJobCreatePaymentError: false,
  schedule: {
    dateRange: {
      start: moment().startOf('month').format('YYYY-MM-DD'),
      end: moment().endOf('month').format('YYYY-MM-DD'),
    },
    view: 'calendar',
    filter: 'all',
  },
  showSuccessJobCancelationPopup: false,
  officeJobInvites: {
    jobs: [],
    page: 1,
    limit: 10,
    isFinal: false,
    isActiveRequest: false,
  },

  currentCandidateEndorsement: null,
  showConfirmCandidateAnotherJobPopup: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
  case apiMethodsConst.CREATE_JOB_REQUEST:
    return {
      ...state,
      job: action.payload,
      isOverlappingJob: false,
      message: '',
    };
  case actions.SHOW_JOB_CREATE_PAYMENT_ERROR:
    return {
      ...state,
      showJobCreatePaymentError: true,
    };
  case actions.HIDE_JOB_CREATE_PAYMENT_ERROR:
    return {
      ...state,
      showJobCreatePaymentError: false,
    };
  case apiMethodsConst.FETCH_CANCELLATION_REASONS_REQUEST:
    return {
      ...state,
      cancelationReasons: action.payload,
    };
  case actions.CHANGE_CALENDAR_TABS:
    return {
      ...state,
      selectedCalendarTab: action.tab,
    };
  case 'ADJUST_HOURS_ACTION_SUCCESS': {
    return {
      ...state,
      adjustmentsSend: [...state.adjustmentsSend, action.payload],
    };
  }
  case apiMethodsConst.FETCH_JOBS_WITH_ADJUSTMENTS_REQUEST: {
    return {
      ...state,
      adjustments: action.payload,
    };
  }

  case 'CHANGE_STATUS_ADJUSTMENT_SUCCESS':
    const index = state.adjustments.findIndex((job) => job.id === action.payload);
    return {
      ...state,
      adjustments: [...state.adjustments.slice(0, index), ...state.adjustments.slice(index + 1)],
    };
  case apiMethodsConst.GET_ALL_JOBS_REQUEST:
    const { pendingCount, openDates, pendingDates, confirmedDates, pastDates, todayDates } =
        action.payload;

    const cellSize = 18;
    const reduceFunc = (prev, curr) => ({ ...curr, ...prev });
    const higherMapFunc =
        (color, border) =>
          ({ date }) => ({
            [moment(date).format('YYYY-MM-DD')]: {
              backgroundColor: color,
              color: border ? Colors.neutral_500 : Colors.white,
              border: border ? '2px solid #3DAFC9' : null,
              width: cellSize,
              height: cellSize,
              borderRadius: '48%',
            },
          });

    const selectedDaysOnCalendar = [
      ...todayDates,
      ...openDates,
      ...pendingDates,
      ...confirmedDates,
      ...pastDates,
    ]
      .map(({ date }) => ({
        [moment(date).format('YYYY-MM-DD')]: new Date(date),
      }))
      .reduce((a, b) => ({ ...a, ...b }), {});

    const datesWithStatus = [
      ...todayDates.map((data) => ({ ...data, status: 'today' })),
      ...openDates.map((data) => ({ ...data, status: 'open' })),
      ...pendingDates.map((data) => ({ ...data, status: 'pending' })),
      ...confirmedDates.map((data) => ({ ...data, status: 'filled' })),
      ...pastDates.map((data) => ({ ...data, status: 'past' })),
    ];

    const jobsPerDay = datesWithStatus
      .map(({ localDate, date, ...rest }) => ({
        [moment(localDate, 'ddd, MM/DD/YYYY').format('YYYY-MM-DD')]: [
          { dateStart: date, localDate, ...rest },
        ],
      }))
      .reduce((res, curr) => {
        const key = Object.keys(curr)[0];
        return {
          ...res,
          [key]: [...(res[key] || []), ...curr[key]],
        };
      }, {});

    // Clear days that are in the current schedule date range
    const oldJobsPerDay = { ...state.jobsPerDay };
    for (const dateKey in oldJobsPerDay) {
      const date = moment(dateKey, 'YYYY-MM-DD');
      if (
        date.isBetween(state.schedule.dateRange.start, state.schedule.dateRange.end, null, '[]')
      ) {
        oldJobsPerDay[dateKey] = [];
      }
    }

    const jobsOnCalendarStyle = [
      ...todayDates.map(higherMapFunc(Colors.primary_500)),
      ...confirmedDates.map(higherMapFunc(Colors.secondary_500)),
      ...openDates.map(higherMapFunc(Colors.success_500)),
      ...pendingDates.map(higherMapFunc(Colors.error_450)),
      ...pastDates.map(higherMapFunc(Colors.neutral_300)),
    ].reduce(reduceFunc, {});

    return {
      ...state,
      jobsOnCalendarStyle: { ...state.jobsOnCalendarStyle, ...jobsOnCalendarStyle },
      selectedDaysOnCalendar: { ...state.selectedDaysOnCalendar, ...selectedDaysOnCalendar },
      jobsPerDay: { ...oldJobsPerDay, ...jobsPerDay },
      pendingCount,
      selectedCalendarTab: todayDates.length
        ? 'today'
        : pendingCount
          ? 'pending-counter'
          : 'filled',
    };
  case 'GET_JOBS_BY_STATUS_DATE_ACTION_REQUEST':
    const { status, enablePagination, date } = action.payload.status;

    if (
      (!enablePagination && status !== state.jobListPagination.status) ||
        (date && !state.jobListPagination.date)
    ) {
      return {
        ...state,
        jobList: [],
        jobListPagination: {
          ...state.jobListPagination,
          page: 1,
          status,
          date,
        },
      };
    }

    return state;
  case apiMethodsConst.GET_JOBS_BY_STATUS_DATE_REQUEST:
    const data = action.payload.jobs;

    if (state.jobListPagination.isFinal && state.jobListPagination.page > 1) return state;

    if (!action.payload.enablePagination) {
      return {
        ...state,
        jobList: action.payload.jobs,
        jobListPagination: {
          ...state.jobListPagination,
          isFinal: data.length === 0 || data.length < state.jobListPagination.limit,
        },
      };
    }

    return {
      ...state,
      jobList: [...state.jobList, ...action.payload.jobs],
      jobListPagination: {
        ...state.jobListPagination,
        page: action.payload.page,
        isFinal: data.length === 0 || data.length < state.jobListPagination.limit,
      },
    };
  case apiMethodsConst.FETCH_JOBS_WITH_TRANSACTIONS:
    const jobs = action.payload.response;

    const mergedJobs = [...state.jobsWithTransaction, ...jobs];

    return {
      ...state,
      jobsWithTransaction: action.payload.page === 1 ? jobs : mergedJobs,
      jobsWithTransactionPagination: {
        ...state.jobsWithTransactionPagination,
        page: action.payload.page,
        limit: action.payload.limit,
        isFinal: jobs.length === 0 || jobs.length < state.jobsWithTransactionPagination.limit,
      },
    };
  case apiMethodsConst.FETCH_AVERAGE_RATING:
    return {
      ...state,
      averageRating: action.payload,
    };
  case 'IS_OVERLAPPING_JOB':
    return {
      ...state,
      isOverlappingJob: true,
      message: action.payload,
    };
  case apiMethodsConst.CANCEL_JOB_REQUEST:
    return {
      ...state,
      actionRequiredJobs: state.actionRequiredJobs?.filter(
        (job) => job.id !== action.payload?.jobId,
      ),
      showSuccessJobCancelationPopup: !action.payload?.redirect,
    };
  case actions.HIDE_SUCCESS_JOB_CANCELATION:
    return {
      ...state,
      showSuccessJobCancelationPopup: false,
    };
  case apiMethodsConst.FETCH_CANDIDATE_OVERTIME_REQUEST:
    return {
      ...state,
      job: {
        ...state.job,
        regularAndOvertimeHours: action.payload?.regularAndOvertimeHours,
        totalOvertime: action.payload?.totalOvertime,
        totalRegular: action.payload?.totalRegular,
        convenience_fee: action.payload?.convenience_fee,
        convenience_fee_percent: action.payload?.convenience_fee_percent,
        employeeOptionFee: action.payload?.employeeOptionFee,
        total: action.payload?.total,
        tm_fee: action.payload?.tm_fee,
        tm_fee_percent: action.payload?.tm_fee_percent,
      },
    };
  case apiMethodsConst.FETCH_JOB_REQUEST:
  case apiMethodsConst.DECLINE_COUNTER_OFFER_REQUEST:
  case actions.SELECT_JOB_ACTION:
    return {
      ...state,
      job: action.payload,
    };
  case apiMethodsConst.REPORT_INCIDENT_REQUEST:
    return {
      ...state,
      todayJobs: state.todayJobs.map((job) => {
        if (job.id === action.payload.id) {
          return { ...job, incident: true };
        }
        return job;
      }),
    };
  case actions.SHOW_REPORT_INCIDENT_SUCCESS_POPUP:
    return {
      ...state,
      showReportIncidentSuccessPopup: action.payload,
    };
  case actions.HIDE_REPORT_INCIDENT_SUCCESS_POPUP:
    return {
      ...state,
      showReportIncidentSuccessPopup: null,
    };
  case apiMethodsConst.FETCH_EXPIRED_COUNTER_OFFERS:
    return {
      ...state,
      expiredCounteOffers: action.payload,
    };
  case apiMethodsConst.FETCH_DASHBOARD_INFO:
    return {
      ...state,
      dashboardInfo: action.payload,
    };
  case apiMethodsConst.FETCH_OPEN_JOB_REQUEST:
    const mergedOpenJobs = [...state.openJobs, ...action.payload];

    return {
      ...state,
      openJobs: state.dashboardJobListPagination.page === 1 ? action.payload : mergedOpenJobs,
      dashboardJobListPagination: {
        ...state.dashboardJobListPagination,
        isFinal:
            action.payload.length === 0 ||
            action.payload.length < state.dashboardJobListPagination.limit,
        isActiveRequest: false,
      },
    };
  case 'FETCH_SCHEDULED_JOBS_ACTION_REQUEST':
  case 'FETCH_ACTION_REQUIRED_JOBS_ACTION_REQUEST':
  case 'FETCH_OPEN_JOBS_ACTION_REQUEST':
  case 'FETCH_TODAY_JOBS_ACTION_REQUEST':
    return {
      ...state,
      dashboardJobListPagination: {
        ...state.dashboardJobListPagination,
        page: action.payload.page,
        limit: action.payload.limit,
        isFinal: false,
        isActiveRequest: true,
      },
    };
  case apiMethodsConst.FETCH_SCHEDULED_JOBS_REQUEST:
    return {
      ...state,
      scheduledJobs:
          state.dashboardJobListPagination.page === 1
            ? action.payload
            : [...state.scheduledJobs, ...action.payload],
      dashboardJobListPagination: {
        ...state.dashboardJobListPagination,
        isFinal:
            action.payload.length === 0 ||
            action.payload.length < state.dashboardJobListPagination.limit,
        isActiveRequest: false,
      },
    };
  case apiMethodsConst.FETCH_ACTION_REQUIRED_JOBS_REQUEST:
    return {
      ...state,
      actionRequiredJobs:
          state.dashboardJobListPagination.page === 1
            ? action.payload
            : [...state.actionRequiredJobs, ...action.payload],
      dashboardJobListPagination: {
        ...state.dashboardJobListPagination,
        page: state.dashboardJobListPagination.page,
        isFinal:
            action.payload.length === 0 ||
            action.payload.length < state.dashboardJobListPagination.limit,
        isActiveRequest: false,
      },
    };
  case apiMethodsConst.FETCH_TODAY_JOBS_REQUEST:
    return {
      ...state,
      todayJobs:
          state.dashboardJobListPagination.page === 1
            ? action.payload
            : [...state.todayJobs, ...action.payload],
      dashboardJobListPagination: {
        ...state.dashboardJobListPagination,
        page: state.dashboardJobListPagination.page,
        isFinal:
            action.payload.length === 0 ||
            action.payload.length < state.dashboardJobListPagination.limit,
        isActiveRequest: false,
      },
    };
  case actions.UPDATE_RECEIPT_DISPLAY_MODE:
    return {
      ...state,
      receiptDisplayMode: action.payload,
    };
  case 'FETCH_RECEIPT_LIST_REQUEST':
    return {
      ...state,
      receiptsPagination: {
        page: action.payload.page,
        limit: action.payload.limit,
      },
    };
  case apiMethodsConst.FETCH_RECEIPTS_REQUEST:
    const receipts = action.payload.response;
    const { page, total, limit } = action.payload;

    const mergedReceipts = [...state.receipts, ...receipts];
    return {
      ...state,
      receipts: page === 1 ? receipts : mergedReceipts,
      receiptsPagination: {
        ...state.receiptsPagination,
        page,
        limit,
      },
      totalReceipts: total,
      isFinalPage:
          receipts.length === 0 ||
          receipts.length < state.receiptsPagination.limit ||
          receipts.length === state.totalReceipts,
      isActiveRequest: false,
    };
  case apiMethodsConst.FETCH_RECEIPTS_REPORT_REQUEST:
    return {
      ...state,
      receiptReport: action.payload,
    };
  case actions.CLEAR_RECEIPTS_REPORT:
    return {
      ...state,
      receiptReport: '',
    };
  case actions.CLEAR_ALL_RECEIPTS:
    return {
      ...state,
      receipts: [],
      jobsWithTransaction: [],
    };
  case actions.CLOSE_OVERLAPPING_MODAL:
    return {
      ...state,
      isOverlappingJob: false,
    };
  case 'UPDATE_JOB_ACTION_SUCCESS':
    return {
      ...state,
      job: action.payload,
    };
  case 'SELECT_OFFICE_SUCCESS':
    return initialState;

  case 'FETCH_INVOICE_LIST_REQUEST':
    return {
      ...state,
      invoicesPagination: {
        page: action.payload.page,
        limit: action.payload.limit,
      },
      isActiveRequestInvoices: true,
    };
  case apiMethodsConst.FETCH_INVOICES_REQUEST:
    const { invoices } = action.payload;
    const pageInvoices = action.payload.page;
    const totalInvoices = action.payload.total;
    const limitInvoices = action.payload.limit;
    const { totalUnpaid } = action.payload;

    const mergedInvoices = [...state.invoices, ...invoices];
    return {
      ...state,
      invoices: pageInvoices === 1 ? invoices : mergedInvoices,
      invoicesPagination: {
        ...state.invoicesPagination,
        page: pageInvoices,
        limit: limitInvoices,
      },
      totalInvoices,
      totalUnpaid,
      isFinalPageInvoices:
          invoices.length === 0 ||
          invoices.length < state.invoicesPagination.limitInvoices ||
          invoices.length === state.totalInvoices,
      isActiveRequestInvoices: false,
    };
  case apiMethodsConst.INVITE_FAVORITE_TO_JOB_REQUEST:
    return {
      ...state,
      job: {
        ...state.job,
        favoriteInvites: [
          ...state.job?.favoriteInvites,
          ...action.payload.userIds.map((id) => ({ professional: id })),
        ],
      },
    };

  case actions.SHOW_CONFIRM_CANDIDATE_ANOTHER_JOB_POPUP:
    return {
      ...state,
      showConfirmCandidateAnotherJobPopup: true,
    };
  case actions.HIDE_CONFIRM_CANDIDATE_ANOTHER_JOB_POPUP:
    return {
      ...state,
      showConfirmCandidateAnotherJobPopup: false,
    };

  case apiMethodsConst.CONFIRM_JOB_REQUEST:
    return {
      ...state,
      actionRequiredJobs: state.actionRequiredJobs?.filter(
        (job) => job.id !== action.payload?.id,
      ),
    };
  case actions.CHANGE_SCHEDULE:
    return {
      ...state,
      schedule: {
        ...state.schedule,
        ...(action.payload && { [action.payload.option]: action.payload.value }),
      },
    };
  case actions.FETCH_OPEN_JOBS_FOR_RECENT_PROFESSIONAL_ACTION:
    return {
      ...state,
      recentOpenJobs: {
        ...state.recentOpenJobs,
        page: action.page,
        isFinal: false,
        isActiveRequest: true,
      },
    };
  case apiMethodsConst.FETCH_OPEN_JOBS_FOR_RECENT_PROFESSIONAL_REQUEST:
    return {
      ...state,
      recentOpenJobs: {
        professionalId: action.payload.professionalId,
        limit: state.recentOpenJobs.limit,
        page: action.payload.page,
        offers:
            action.payload.page === 1
              ? action.payload.offers
              : [...state.recentOpenJobs.offers, ...action.payload.offers],
        isFinal:
            action.payload.offers.length === 0 ||
            action.payload.offers.length < state.recentOpenJobs.limit,
        isActiveRequest: false,
      },
    };
  case actions.FETCH_OFFICE_JOB_INVITES_ACTION:
    return {
      ...state,
      officeJobInvites: {
        ...state.officeJobInvites,
        page: action.page,
        isFinal: false,
        isActiveRequest: true,
      },
    };
  case apiMethodsConst.FETCH_OFFICE_JOB_INVITES_REQUEST:
    const totalJobsArr = [...state.officeJobInvites.jobs, ...action.payload.jobs];
    return {
      ...state,
      officeJobInvites: {
        ...action.payload,
        jobs: action.payload.page === 1 ? action.payload.jobs : totalJobsArr,
        isFinal:
            action.payload.jobs.length === 0 ||
            action.payload.jobs.length < state.officeJobInvites.limit,
        isActiveRequest: false,
      },
    };
  case apiMethodsConst.FETCH_USER_PROCEDURE_ENDORSEMENT_REQUEST:
    return {
      ...state,
      currentCandidateEndorsement: action.payload,
    };
  default:
    return state;
  }
};
