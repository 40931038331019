import { Colors } from '../../../../../themes/colors';

export const Value = ({ text, customStyles }) => (
  <span
    style={{
      fontFamily: 'Nunito',
      color: Colors.neutral_600,
      ...customStyles,
    }}
  >
    {text}
  </span>
);
