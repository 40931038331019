import { Colors } from '../../themes/colors';
import AttendanceBadge from './AttendanceBadge';
import MultiProfessionalBadges from './MultiProfessionalBadges';

export function CandidateBadges({ candidate }) {
  const isBadgesV2 = !!candidate?.badges?.length;

  if (!isBadgesV2 && !candidate?.currentRank?.rank) {
    return null;
  }

  return (
    <div
      style={{
        padding: '14px 18px',
        border: `1px solid ${Colors.neutral_150}`,
        marginBottom: 4,
      }}
    >
      <p className="global_font" style={{ margin: 0, color: Colors.neutral_600, fontSize: 20 }}>
        <b>Badges:</b>
      </p>

      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: isBadgesV2 || (!isBadgesV2 && candidate?.currentRank?.rank) ? -20 : 0,
          marginRight: 12,
        }}
      >
        {isBadgesV2 ? (
          <MultiProfessionalBadges badges={candidate?.badges} />
        ) : (
          <AttendanceBadge currentRank={candidate?.currentRank} />
        )}
      </div>
    </div>
  );
}
