import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import NewPhotoFeature from '../../../../images/NewAutoConfirmOption.svg';
import { Colors } from '../../../../themes/colors';
import ConfirmPopup from '../../../shared/ConfirmPopup';

export default function NewAutoConfirmOptionPopup() {
  const user = useSelector((state) => state.user.selectedChildOffice ?? state.user.user);
  const [showPopup, setShowPopup] = useState(false);
  const isSameOrBefore = moment().isSameOrBefore(moment('2024-10-31', 'YYYY-MM-DD'));

  const getStorage = () => {
    const showNewAutoConfirmOptionPopup = localStorage.getItem(
      `showNewAutoConfirmOptionPopup-${user?.id}`,
    );

    if (showNewAutoConfirmOptionPopup === null) {
      setShowPopup(true);
    }
  };

  const setStorage = () => {
    localStorage.setItem(`showNewAutoConfirmOptionPopup-${user?.id}`, 'false');
    setShowPopup(false);
  };

  const handleConfirm = () => {
    setStorage();
  };

  useEffect(() => {
    getStorage();
  }, [user]);

  return (
    user?.is_profile_complete === true &&
    isSameOrBefore &&
    showPopup && (
      <ConfirmPopup
        Icon={() => (
          <img
            alt="Upload Avatar Feature"
            src={NewPhotoFeature}
            style={{ width: 224, height: 158, marginBottom: 24 }}
          />
        )}
        title="New Auto-Confirm Option!"
        description={
          <>
            We added a new auto-confirm option <b>exclusively for your favorites</b> to make it
            easier for you to re-connect with the professionals you know and trust!
            <br />
            <br />
            This auto-confirm option will act independently from our standard auto-confirm option so
            you can customize your experience according to your office’s needs!
          </>
        }
        descriptionStyle={{ color: Colors.neutral_500, marginBottom: 32, width: '90%' }}
        rightButtonText="Close"
        rightButtonAction={handleConfirm}
        closePopup={handleConfirm}
      />
    )
  );
}
