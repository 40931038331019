import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import {
  getAlerts,
  GET_ALL_ALERTS_ACTION,
  deleteAllAlerts,
  DELETE_ALL_ALERTS_ACTION,
  deleteOneAlert,
  DELETE_ONE_ALERT_ACTION,
  getUserMessages,
  selectContentTypeInbox,
  GET_USER_MESSAGES,
  showMessage,
  hideMessage,
} from '../actions';
import { createLoadingSelector, createErrorObjectSelector } from '../api/selectors';
import Alerts from './Alerts';
import { Colors } from '../themes/colors';
import Messages from './messages/Messages';
import { trackEvent } from '../api/analytics';

const LIMIT_VALUE = 15;
export default ({ history }) => {
  const selectedContent = useSelector((state) => state.messages.selectedContent);
  const conversationCount = useSelector((state) => state.user.user.conversationCount);
  const dashboardInfo = useSelector((state) => state.job.dashboardInfo);

  const isFinalPage = useSelector((state) => state.messages.isFinalPage);
  const isFinalPageAlerts = useSelector((state) => state.alerts.isFinalPage);

  const isActiveRequest = useSelector((state) => state.messages.isActiveRequest);
  const isActiveRequestAlerts = useSelector((state) => state.alerts.isActiveRequest);

  const selectedOfficeId = useSelector((state) => state.user.selectedOfficeId);

  const dispatch = useDispatch();

  const loadingSelector = createLoadingSelector([
    GET_ALL_ALERTS_ACTION,
    GET_USER_MESSAGES,
    DELETE_ALL_ALERTS_ACTION,
    DELETE_ONE_ALERT_ACTION,
  ]);
  const errorSelector = createErrorObjectSelector([
    GET_ALL_ALERTS_ACTION,
    GET_USER_MESSAGES,
    DELETE_ALL_ALERTS_ACTION,
    DELETE_ONE_ALERT_ACTION,
  ]);

  const { page } = useSelector((store) => store.messages.searchParams);
  const searchParamsAlerts = useSelector((store) => store.alerts.searchParams);

  const isLoading = useSelector((state) => loadingSelector(state));
  const error = useSelector((state) => errorSelector(state));
  const refMessages = useRef(null);
  const refAlerts = useRef(null);

  const handleScrollMessages = () => {
    const messagesScroll = refMessages?.current;

    const userScrollHeight = messagesScroll?.scrollHeight - messagesScroll?.scrollTop;
    const clientHeight = messagesScroll?.clientHeight;

    if (userScrollHeight <= clientHeight && !isActiveRequest) {
      dispatch(getUserMessages({ page: page + 1, limit: LIMIT_VALUE }));
    }
  };

  const handleScrollAlerts = () => {
    const alertsScroll = refAlerts.current;

    const userScrollHeight = alertsScroll?.scrollHeight - alertsScroll?.scrollTop;
    const clientHeight = alertsScroll?.clientHeight + 20;

    if (userScrollHeight <= clientHeight && !isActiveRequestAlerts && !isFinalPageAlerts) {
      dispatch(getAlerts({ page: searchParamsAlerts?.page + 1, limit: LIMIT_VALUE }));
    }
  };

  useEffect(() => {
    if (selectedContent === 'alerts') {
      dispatch(getAlerts({ page: 1, limit: LIMIT_VALUE }));
    } else if (selectedContent === 'messages') {
      dispatch(getUserMessages({ page: 1, limit: LIMIT_VALUE }));
    }
  }, [selectedOfficeId]);

  const selectInboxType = (type) => {
    if (type === 'alerts') {
      dispatch(getAlerts({ page: 1, limit: LIMIT_VALUE }));
      dispatch(selectContentTypeInbox({ payload: 'alerts' }));
    } else if (type === 'messages') {
      dispatch(getUserMessages({ page: 1, limit: LIMIT_VALUE }));
      dispatch(selectContentTypeInbox({ payload: 'messages' }));
    }
  };

  const deleteAll = () => {
    dispatch(
      showMessage({
        title: 'Confirm Delete',
        message: 'Are you sure you want to delete all your messages?',
        isError: true,
        popupProps: {
          leftButtonText: 'Go Back',
          leftButtonAction: () => dispatch(hideMessage()),
          rightButtonText: 'Remove',
          rightButtonAction: () => {
            dispatch(deleteAllAlerts());
            dispatch(hideMessage());
          },
        },
      }),
    );
  };

  const selectedAlert = (job) => {
    // TODO: - follow new alert schema
    if (job.job_path === 'DNT_OFFER') {
      trackEvent('Office Dashboard - Inbox Notifications Tile Clicked', {
        actionNeededShiftsCount: dashboardInfo?.actionRequired,
        scheduledShiftsCount: dashboardInfo?.scheduled,
        openShiftsCount: dashboardInfo?.activeRequest,
      });

      history.push(`/dashboard/job/${job.joboffer_id}`, {
        jobId: job.joboffer_id,
      });
    }
  };

  const selectedMessage = (job) => {
    trackEvent('Office Dashboard - Inbox Chats Tile Clicked', {
      actionNeededShiftsCount: dashboardInfo?.actionRequired,
      scheduledShiftsCount: dashboardInfo?.scheduled,
      openShiftsCount: dashboardInfo?.activeRequest,
    });

    history.push(`/dashboard/chat/${job.id}`, { jobId: job.id });
  };

  const deleteOneAlertSubmit = (alertId) => {
    dispatch(deleteOneAlert({ alertId }));
  };

  const renderContent = () => {
    if (selectedContent === 'alerts') {
      return (
        <div
          ref={refAlerts}
          onScroll={handleScrollAlerts}
          style={{ overflowY: 'scroll', maxHeight: 500 }}
        >
          <Alerts
            isLoading={isLoading}
            selectedJobs={selectedAlert}
            deleteAll={deleteAll}
            deleteOneAlert={deleteOneAlertSubmit}
          />
          {!isFinalPageAlerts && isLoading ? (
            <div
              style={{
                width: '100%',
                height: 100,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ClipLoader sizeUnit="px" size={50} color={Colors.primary_500} loading />
            </div>
          ) : (
            <div />
          )}
        </div>
      );
    }
    if (selectedContent === 'messages') {
      return (
        <div
          ref={refMessages}
          onScroll={handleScrollMessages}
          style={{ overflowY: 'scroll', maxHeight: 500 }}
        >
          <Messages
            isLoading={isLoading}
            selectedJobs={selectedMessage}
            deleteAll={deleteAll}
            deleteOneAlert={deleteOneAlertSubmit}
          />
          {!isFinalPage && isLoading ? (
            <div
              style={{
                width: '100%',
                height: 100,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ClipLoader sizeUnit="px" size={50} color={Colors.primary_500} loading />
            </div>
          ) : (
            <div />
          )}
        </div>
      );
    }
  };

  return (
    <div className="inbox">
      <div style={{ width: '100%', margin: '15px 25px' }}>
        <h1
          className="global_font f-dark bold"
          style={{
            fontSize: 32,
            textAlign: 'center',
            marginRight: 20,
            marginLeft: 30,
            marginBottom: 0,
          }}
        >
          Inbox
        </h1>
        <div
          style={{
            width: '90%',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div style={{ marginLeft: 20 }}>
            <h4
              onClick={() => selectInboxType('alerts')}
              className={`global_font ${selectedContent === 'alerts' ? 'f-dark ' : 'f-light'} bold`}
              style={{
                fontSize: 24,
                fontWeight: selectedContent === 'alerts' ? 'bold' : 'normal',
                marginRight: 20,
                cursor: 'pointer',
                marginBottom: selectedContent === 'alerts' ? 0 : 7,
                textAlign: 'center',
                marginLeft: 20,
              }}
            >
              Notifications
            </h4>
            {selectedContent === 'alerts' ? (
              <div
                style={{
                  height: 2,
                  display: 'flex',
                  width: '100%',
                  backgroundColor: Colors.secondary_500,
                  marginTop: 5,
                }}
              />
            ) : (
              <div />
            )}
          </div>
          <div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <h4
                onClick={() => {
                  selectInboxType('messages');
                }}
                className={`global_font ${
                  selectedContent === 'messages' ? 'f-dark ' : 'f-light'
                } bold`}
                style={{
                  fontSize: 24,
                  fontWeight: selectedContent === 'messages' ? 'bold' : 'normal',
                  cursor: 'pointer',
                  marginLeft: 20,
                  marginRight: 20,
                  marginBottom: selectedContent === 'messages' ? 0 : 7,
                }}
              >
                Chats
              </h4>
              {conversationCount ? (
                <div
                  style={{
                    backgroundColor: '#f4511d',
                    borderRadius: 50,
                    width: 10,
                    height: 10,
                    marginRight: 5,
                    marginTop: 20,
                  }}
                />
              ) : (
                <div />
              )}
            </div>
            {selectedContent === 'messages' ? (
              <div
                style={{
                  height: 2,
                  display: 'flex',
                  width: '100%',
                  backgroundColor: Colors.secondary_500,
                  marginTop: 5,
                }}
              />
            ) : (
              <div />
            )}
          </div>
        </div>
        {renderContent()}
      </div>
    </div>
  );
};
