import '../../themes/global.scss';
import '../../themes/job_summary.scss';
import '../../themes/payment.scss';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  DELETE_DEFAULT_PAYMENT_ACTION,
  DELETE_PAYMENT_ACTION,
  GET_ALL_PAYMENTS_ACTION,
  SELECT_PAYMENT_ACTION,
  getAllPaymnets,
} from '../../actions';
import { createLoadingSelector } from '../../api/selectors';
import BackButton from '../../commonComponents/BackButton';
import CustomSpinner from '../../commonComponents/CustomSpinner';
import LockClosedIcon from '../../images/lock-closed.svg';
import { Colors } from '../../themes/colors';
import { BankList } from './components/BankList';
import { CardList } from './components/CardList';

const loadingSelector = createLoadingSelector([
  GET_ALL_PAYMENTS_ACTION,
  SELECT_PAYMENT_ACTION,
  DELETE_PAYMENT_ACTION,
  DELETE_DEFAULT_PAYMENT_ACTION,
]);

export default function Payment() {
  const dispatch = useDispatch();
  const [selectedPayment, setSelectedPayment] = useState(null);
  const isLoading = useSelector((state) => loadingSelector(state));
  const selectedChildOffice = useSelector((state) => state.user.selectedChildOffice);
  const childOffices = useSelector((state) => state.user.childOffices);

  useEffect(() => {
    dispatch(getAllPaymnets());
  }, [dispatch]);

  return (
    <div className="component_container">
      <div className="floating_container" style={{ justifyContent: 'flex-start' }}>
        <div
          style={{
            width: '100%',
            margin: '20px 10px',
          }}
        >
          <BackButton url="/account" />

          <div
            style={{
              display: 'flex',
              width: '100%',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <span
              style={{
                fontFamily: 'Nunito',
                fontWeight: 700,
                fontSize: 32,
                color: Colors.neutral_600,
                marginBottom: 40,
              }}
            >
              Payment Information
            </span>

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: 4,
              }}
            >
              <span
                style={{
                  fontFamily: 'Nunito',
                  fontWeight: 400,
                  fontStyle: 'italic',
                  fontSize: 16,
                  color: Colors.neutral_500,
                }}
              >
                GoTu works with Stripe & Plaid to secure your payment information.
              </span>
              <img src={LockClosedIcon} alt="lock-closed" />
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 12,
              backgroundColor: Colors.neutral_50,
              padding: '56px 32px',
              marginTop: 40,
              minHeight: 400,
            }}
          >
            {childOffices.length > 1 && (
              <div
                style={{
                  backgroundColor: Colors.neutral_100,
                  height: 52,
                  marginTop: -40,
                  marginBottom: 4,
                  display: 'flex',
                  alignItems: 'center',
                  paddingLeft: 32,
                }}
              >
                <span
                  style={{
                    fontFamily: 'Nunito',
                    fontWeight: 700,
                    fontSize: 18,
                    color: Colors.neutral_600,
                  }}
                >
                  Office Name:{' '}
                  <span
                    style={{
                      fontWeight: 400,
                    }}
                  >
                    {selectedChildOffice?.office_name}
                  </span>
                </span>
              </div>
            )}
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
              }}
            >
              {isLoading && <CustomSpinner />}
            </div>
            {!isLoading && (
              <>
                <CardList
                  selectedPayment={selectedPayment}
                  setSelectedPayment={setSelectedPayment}
                />
                <BankList
                  selectedPayment={selectedPayment}
                  setSelectedPayment={setSelectedPayment}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
