import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import history from '../../../../history';
import { CandidateCard } from './CandidateCard';

export const CandidateSelection = () => {
  const { jobId, candidateId } = useParams();
  const job = useSelector((state) => state.job.job);

  const handleOnClick = (id) => {
    history.replace(`/dashboard/job/${jobId}/confirm/${id}`);
  };

  if (job?.multipleCounters?.length === 1) {
    return null;
  }

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        gap: 8,
      }}
    >
      {job?.multipleCounters?.slice(0, 5)?.map((item) => (
        <CandidateCard
          key={item.id}
          item={item}
          isSelected={candidateId === item?.candidate?.id}
          onClick={() => handleOnClick(item?.candidate?.id)}
        />
      ))}
    </div>
  );
};
