import '../../../themes/global.scss';
import '../../../themes/registration.scss';

import { motion } from 'framer-motion';
import { parsePhoneNumberFromString as parsePhoneNumber } from 'libphonenumber-js';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  EDIT_ACCOUNT_INFO_ACTION,
  PHONE_VERIFICATION_ACTION,
  fetchMetadata,
} from '../../../actions';
import { createLoadingSelector } from '../../../api/selectors';
import { AvatarList } from '../../../commonComponents/AvatarList/AvatarList';
import { convertSvgToJpeg } from '../../../commonComponents/AvatarList/utils';
import Button from '../../../commonComponents/Button';
import { ImageDropzone } from '../../../commonComponents/ImageDropzone/ImageDropzone';
import InputSelect from '../../../commonComponents/InputSelect';
import LocationSearch from '../../../commonComponents/LocationSearch';
import { UserProfession } from '../../../enums/UserProfession';
import usePhoneNumber from '../../../hooks/phoneNumber';
import OfficeAvatar1 from '../../../images/avatars/Office-Avatar-1.svg';
import arrowDown from '../../../images/thinArrowDown.svg';
import { Colors } from '../../../themes/colors';
import { getBlobFromBlobUrl } from '../../../utils/Blob';
import { isObjectEqual } from '../../../utils/Object';
import Tooltip from '../../Tooltip';
import ConfirmPopup from '../../shared/ConfirmPopup';
import { Label } from '../components/Label';
import { RadioOption } from '../components/RadioOption';
import {
  bookedOutPatientsOptions,
  numberOfOperatoriesOptions,
  numberOfPermanentStaffOptions,
} from '../utils';
import { trackEvent } from '../../../api/analytics';

const opacityVariants = {
  show: { opacity: 1 },
  hide: { opacity: 0 },
};

const actions = [EDIT_ACCOUNT_INFO_ACTION, PHONE_VERIFICATION_ACTION];
const loadingSelector = createLoadingSelector(actions);

export const DetailedView = ({ submitBtnPressed, isEditing }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => loadingSelector(state));
  const user = useSelector((state) => state.user.user);
  const allMetadata = useSelector((state) => state.user.allMetadata);
  const [officeName, setOfficeName] = useState(user?.office_name || '');
  const [placeId, setPlaceId] = useState(user?.address[0]?.place_id || '');
  const [formattedAddress, setFormattedAddress] = useState(
    user?.address[0]?.formatted_address || '',
  );
  const [cellNumber, setCellNumber] = usePhoneNumber(user?.account_admin[0]?.contact_number || '');
  const [website, setWebsite] = useState(user?.web_site || '');
  const [phoneNumber, setPhone] = usePhoneNumber(user?.contact_number || '');
  const [isParkingFree, setIsParkingFree] = useState(user?.parkingInformation?.isParkingFree);
  const [parkingCost, setParkingCost] = useState(user?.parkingInformation?.cost || '');
  const [parkingCostType, setParkingCostType] = useState(
    user?.parkingInformation?.costType || 'hr',
  );
  const [parkingType, setParkingType] = useState(user?.parkingInformation?.parkingType || '');
  const phoneNumberParsed = parsePhoneNumber(phoneNumber, 'US');
  const parkingCostInformation =
    isParkingFree !== undefined && (isParkingFree || (parkingCost && parkingCostType));
  const isDisabled =
    !officeName ||
    !placeId ||
    !phoneNumber ||
    !phoneNumberParsed ||
    !parkingCostInformation ||
    !parkingType;
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showConfirmAvatarSelectionPopup, setShowConfirmAvatarSelectionPopup] = useState({
    open: false,
    img: null,
    index: null,
  });
  const removeDropzoneImageRef = useRef(null);
  const officeProductivity = {
    operatories: user?.office_info?.productivity?.operatories || null,
    permanentStaff: {
      [UserProfession.RDH]: user?.office_info?.productivity?.permanentStaff?.RDH || null,
      [UserProfession.DA]: user?.office_info?.productivity?.permanentStaff?.DA || null,
      [UserProfession.DN]: user?.office_info?.productivity?.permanentStaff?.DN || null,
    },
    bookedOutPatients: user?.office_info?.productivity?.bookedOutPatients || null,
  };
  const [productivity, setProductivity] = useState(officeProductivity);

  const addressResult = (address) => {
    const { place_id: placeId, formatted_address: formattedAddress } = address;
    setFormattedAddress(formattedAddress);
    setPlaceId(placeId);

    trackEvent('Home Address Added');
  };

  const getProfileImage = async () => {
    if (selectedImage) {
      return { avatar: await getBlobFromBlobUrl(selectedImage) };
    }

    if (!user.profile_image) {
      const defaultIcon = await convertSvgToJpeg(OfficeAvatar1);
      return { avatar: await getBlobFromBlobUrl(defaultIcon.url) };
    }

    return {};
  };

  const validateInfo = async () => {
    const data = {
      office_name: officeName,
      contact_number: phoneNumberParsed.number,
      web_site: website,
      account_admin_contact_number: cellNumber,
      place_id: placeId,
      parkingInformation: {
        isParkingFree,
        cost: Number(parkingCost),
        costType: parkingCostType,
        parkingType,
      },
      ...(!isObjectEqual(officeProductivity, productivity) && { productivity }),
      ...(await getProfileImage()),
    };
    submitBtnPressed(data);
  };

  const handleAvatarSelect = (img, index) => {
    if (selectedAvatar === index) {
      setSelectedAvatar(null);
      setSelectedImage(null);
      return;
    }
    if (selectedImage && !selectedAvatar) {
      setShowConfirmAvatarSelectionPopup({ open: true, img, index });
    } else {
      setSelectedImage(img);
      setSelectedAvatar(index);
      removeDropzoneImageRef.current = !removeDropzoneImageRef.current;
    }
  };

  const onCompletedImage = (img) => {
    trackEvent('Office Sign up - General Information - Profile Picture Submitted');
    setSelectedImage(img);
    setSelectedAvatar(null);
  };

  useEffect(() => {
    if (!allMetadata) {
      dispatch(fetchMetadata());
    }
  }, [allMetadata, dispatch]);

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: 50,
        }}
      >
        <span
          style={{
            fontFamily: 'Nunito',
            fontSize: 16,
            fontStyle: 'italic',
            fontWeight: 400,
            margin: 0,
            color: Colors.neutral_500,
            textAlign: 'center',
          }}
        >
          Pertinent office information will only be shared with your matched professional after they
          are
          <br />
          confirmed by your office.
        </span>
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', gap: 36 }}>
        <div>
          <Label text="Office Address" />
          <LocationSearch
            result={addressResult}
            address={formattedAddress}
            placeholder="Enter Office Address"
          />
        </div>

        <div>
          <Label text="Office Name" />
          <input
            value={officeName}
            className="input-field"
            style={{
              width: '100%',
              maxWidth: '-webkit-fill-available',
              marginBottom: 0,
            }}
            placeholder="Enter Office Name"
            onChange={(e) => setOfficeName(e.target.value)}
          />
        </div>

        <div>
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 16 }}>
            <div>
              <Label text="Mobile Number" />
              <input
                value={phoneNumber}
                className="input-field"
                style={{
                  width: '100%',
                  maxWidth: '-webkit-fill-available',
                }}
                placeholder="Mobile Number"
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <div>
              <Label text="Office Number" required={false} />
              <input
                value={cellNumber}
                className="input-field"
                style={{
                  width: '100%',
                  maxWidth: '-webkit-fill-available',
                }}
                placeholder="Office Number"
                onChange={(e) => setCellNumber(e.target.value)}
              />
            </div>
          </div>
          <span
            style={{
              fontFamily: 'Nunito',
              fontStyle: 'italic',
              color: Colors.neutral_500,
              margin: '0px 0px 12px 0px',
            }}
          >
            Please make sure the number you entered, it’s the best to receive real time updates and
            notifications regarding your matched professionals and posted shifts.
          </span>
        </div>

        <div>
          <Label text="Number of Operatories" required={false} marginBottom={20} />

          <InputSelect
            value={productivity.operatories}
            setValue={(value) => setProductivity((prev) => ({ ...prev, operatories: value }))}
            options={numberOfOperatoriesOptions.map((item) => ({ title: item, value: item }))}
          />
        </div>

        <div>
          <Label
            text="Number of Permanent Staff at this Office"
            required={false}
            marginBottom={20}
          />

          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr',
              gap: 26,
            }}
          >
            {[
              { title: 'Dental Hygienists', value: UserProfession.RDH },
              { title: 'Dental Assistants', value: UserProfession.DA },
              { title: 'Doctors', value: UserProfession.DN },
            ].map((item) => (
              <div key={item.value} style={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <span
                  style={{
                    fontFamily: 'Nunito',
                    fontSize: 16,
                    fontWeight: 400,
                    marginBottom: 10,
                    color: Colors.neutral_600,
                  }}
                >
                  {item.title}:
                </span>
                <InputSelect
                  value={productivity.permanentStaff[item.value]}
                  setValue={(value) =>
                    setProductivity((prev) => ({
                      ...prev,
                      permanentStaff: {
                        ...prev.permanentStaff,
                        [item.value]: value,
                      },
                    }))
                  }
                  options={numberOfPermanentStaffOptions.map((item) => ({
                    title: item,
                    value: item,
                  }))}
                />
              </div>
            ))}
          </div>
        </div>

        <div>
          <Label
            text="How Far Are You Currently Booking Out Patients?"
            required={false}
            marginBottom={20}
          />

          <InputSelect
            value={productivity.bookedOutPatients}
            setValue={(value) => setProductivity((prev) => ({ ...prev, bookedOutPatients: value }))}
            options={bookedOutPatientsOptions.map((item) => ({ title: item, value: item }))}
          />
        </div>

        <div>
          <Label text="Parking" />

          <p
            style={{
              fontFamily: 'Nunito',
              color: Colors.neutral_600,
              margin: '0px 0px 12px 0px',
            }}
          >
            Free parking is available on site.
          </p>

          <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
            <RadioOption
              onClick={() => setIsParkingFree(true)}
              checked={isParkingFree}
              text="Yes"
            />
            <div style={{ display: 'flex', gap: 4 }}>
              <RadioOption
                onClick={() => setIsParkingFree(false)}
                checked={isParkingFree === false}
                text="No"
              />
              {isParkingFree === false && (
                <motion.div
                  animate={isParkingFree === false ? 'show' : 'hide'}
                  variants={opacityVariants}
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '120px 168px 176px',
                    alignItems: 'center',
                    marginTop: -12,
                    marginLeft: 32,
                    marginBottom: -10,
                  }}
                >
                  <Label text="Parking cost:" marginBottom={0} required={false} />
                  <input
                    className="partnership-code-input"
                    type="text"
                    value={parkingCost ? `US$ ${parkingCost}` : ''}
                    placeholder="US$ --,--"
                    onChange={(e) => {
                      const value = e.target.value?.replace('US$ ', '');

                      if (/^\d+(\.\d{0,2})?$/.test(value) || value === '') {
                        setParkingCost(value);
                      }
                    }}
                    onClick={(e) => {
                      e.currentTarget.value = '';
                      e.currentTarget.value = parkingCost ? `US$ ${parkingCost}` : '';
                    }}
                    style={{
                      ...Styles.textField,
                      width: 100,
                      marginLeft: 12,
                      marginRight: 12,
                    }}
                  />

                  <InputSelect
                    value={parkingCostType}
                    setValue={(value) => setParkingCostType(value)}
                    options={[
                      { title: 'per hour', value: 'hr' },
                      { title: 'per day', value: 'day' },
                    ]}
                  />
                </motion.div>
              )}
            </div>
          </div>
        </div>

        <div>
          <Label text="Parking Type" />
          <InputSelect
            value={parkingType}
            setValue={(value) => setParkingType(value)}
            options={allMetadata?.parkingTypes?.map((item) => ({ title: item, value: item }))}
          />
        </div>

        <div>
          <Label text="Website" required={false} />
          <input
            className="input-field"
            type="text"
            value={website}
            style={{
              width: '100%',
              maxWidth: '-webkit-fill-available',
            }}
            onChange={(e) => setWebsite(e.target.value)}
            placeholder="Company Website"
          />
        </div>

        <div>
          <div style={{ display: 'flex', gap: 4 }}>
            <Label text="Office Photo" required={false} />
            <Tooltip content="Uploading a photo or a logo of your business to your account allows professionals to have a better idea of where/who they will be working with." />
          </div>
          <span
            style={{
              fontFamily: 'Nunito',
              color: Colors.neutral_600,
              fontSize: 16,
            }}
          >
            Choose a photo from your library or choose an avatar.
            <br />
            <span
              style={{
                color: Colors.neutral_500,
                fontStyle: 'italic',
              }}
            >
              (Maximum upload file size 2MB)
            </span>
          </span>
          <div
            style={{
              marginTop: 44,
              paddingLeft: 20,
              paddingRight: 20,
              display: 'flex',
              gap: 40,
            }}
          >
            <ImageDropzone
              key={removeDropzoneImageRef.current}
              onComplete={(img) => onCompletedImage(img)}
            />
            <div
              style={{
                width: 1,
                backgroundColor: Colors.neutral_100,
              }}
            />
            <div>
              <AvatarList onSelect={handleAvatarSelect} selected={selectedAvatar} />
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          marginTop: 100,
          marginBottom: 110,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button
          isLoading={isLoading}
          text={isEditing ? 'Save' : 'Continue'}
          disabled={isDisabled}
          onClick={validateInfo}
        />
      </div>

      {showConfirmAvatarSelectionPopup?.open && (
        <ConfirmPopup
          title="Replace Photo"
          titleStyle={{ marginTop: 20 }}
          description="Are you sure you want to replace your office photo/logo for this avatar?"
          descriptionStyle={{ marginBottom: 50 }}
          leftButtonText="Go Back"
          leftButtonAction={() =>
            setShowConfirmAvatarSelectionPopup({
              open: false,
              img: null,
              index: null,
            })
          }
          rightButtonText="Replace"
          rightButtonAction={() => {
            setSelectedImage(showConfirmAvatarSelectionPopup.img);
            setSelectedAvatar(showConfirmAvatarSelectionPopup.index);
            removeDropzoneImageRef.current = !removeDropzoneImageRef.current;
            setShowConfirmAvatarSelectionPopup({ open: false, img: null, index: null });
          }}
        />
      )}
    </div>
  );
};

const Styles = {
  selectField: {
    width: '100%',
    backgroundColor: Colors.neutral_50,
    border: 'none',
    padding: '0 20px',
    borderRadius: 5,
    appearance: 'none',
    background: `url('${arrowDown}') no-repeat 96% center #F4F8FA`,
  },
  textField: {
    maxWidth: '100%',
    width: 120,
    backgroundColor: Colors.neutral_50,
    border: 'none',
    padding: '10px 20px',
    margin: 0,
    borderRadius: 5,
    boxShadow: 'none',
    fontFamily: 'Nunito',
    color: Colors.neutral_600,
    fontSize: 16,
  },
};
