import {
  AtIcon,
  CameraIcon,
  CircleCheckSolidIcon,
  PhoneIcon,
  WarningOutlineIcon,
  WarningSolidIcon,
} from '../../images/icons';

export const iconRegistry = {
  phone: PhoneIcon,
  at: AtIcon,
  'warning-solid': WarningSolidIcon,
  'warning-outline': WarningOutlineIcon,
  camera: CameraIcon,
  'circle-check-solid': CircleCheckSolidIcon,
};
