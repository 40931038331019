import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addFavoriteProfessional, blockRecentProfessional } from '../../../../actions';
import { IconButton } from '../../../../commonComponents/IconButton';
import BlockCross from '../../../../images/block-cross.svg';
import blockIcon from '../../../../images/block-user.svg';
import heartOutline from '../../../../images/heart-outline.svg';
import { Colors } from '../../../../themes/colors';
import ConfirmPopup from '../../../shared/ConfirmPopup';
import { InviteButton } from '../InviteButton';
import { trackEvent } from '../../../../api/analytics';

export const ProfessionalCardActions = ({ user }) => {
  const dispatch = useDispatch();
  const [showConfirmBlockPopup, setShowConfirmBlockPopup] = useState(false);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        marginLeft: '24px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 30,
        marginRight: 32,
      }}
    >
      <InviteButton
        user={{
          id: user?.id,
          image: user?.profileImage,
          name: user?.fullname,
          profession: user?.profession,
        }}
      />
      <IconButton
        buttonStyle={{ ...styles.buttonStyle, backgroundColor: Colors.secondary_500 }}
        imgStyle={styles.imgStyle}
        src={heartOutline}
        tooltipContent="Favorite"
        onClick={() => {
          trackEvent('Professional Hub - Recent - Favorite Button Clicked', {
            professionalId: user?.id,
          });
          dispatch(addFavoriteProfessional(user?.id));
        }}
      />
      <IconButton
        buttonStyle={{ ...styles.buttonStyle, backgroundColor: Colors.neutral_400 }}
        imgStyle={styles.imgStyle}
        src={blockIcon}
        tooltipContent="Block"
        onClick={() => setShowConfirmBlockPopup(true)}
      />

      {showConfirmBlockPopup && (
        <ConfirmPopup
          title="Block Professional"
          description="Are you sure you want to block this professional from working with you again?"
          leftButtonText="Go Back"
          leftButtonAction={() => setShowConfirmBlockPopup(false)}
          rightButtonText="Continue"
          rightButtonAction={() => {
            trackEvent('Professional Hub - Recent - Block Professional Submitted', {
              professionalId: user?.id,
            });
            dispatch(blockRecentProfessional({ user }));
            setShowConfirmBlockPopup(false);
          }}
          Icon={() => <img src={BlockCross} style={{ marginBottom: 20 }} alt="block_cross" />}
          closePopup={() => setShowConfirmBlockPopup(false)}
        />
      )}
    </div>
  );
};

const styles = {
  buttonStyle: {
    display: 'flex',
    height: 50,
    width: 50,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    backgroundColor: Colors.primary_500,
  },
  imgStyle: {
    width: 24,
    height: 24,
  },
};
