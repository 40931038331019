import { forwardRef } from 'react';
import history from '../../../history';
import ChevronRight from '../../../images/arrow_right.svg';
import defaultProfile from '../../../images/HY_tooth.png';
import HeartBorderedIcon from '../../../images/icons/HeartBorderedIcon';
import ratingIcon from '../../../images/star_icon.svg';
import { Colors } from '../../../themes/colors';
import { CompanyMatchLabel } from '../../CandidateList/components/UserCard/components/CompanyMatchLabel';
import { ConfirmOrDecline } from './ConfirmOrDecline';

export const CandidateHeader = forwardRef(({ isFavorite, candidate, jobId, notesForTemp }, ref) => (
  <div
    ref={ref}
    style={{
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      marginBottom: 20,
      marginTop: 8,
      height: 120,
    }}
  >
    <div
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: 40,
        paddingRight: 40,
      }}
    >
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <div
          style={{
            position: 'relative',
            width: 104,
            height: 104,
            borderRadius: 104,
            alignItems: 'center',
            justifyContent: 'center',
            border: `3px solid ${isFavorite ? Colors.favorites_400 : 'transparent'}`,
            padding: 2,
          }}
        >
          {candidate && candidate.profile_image ? (
            <img
              src={candidate.profile_image}
              style={{
                width: 104,
                height: 104,
                borderRadius: 104,
                objectFit: 'cover',
              }}
              alt="profile_image"
            />
          ) : (
            <div className="default_circle_container">
              <img src={defaultProfile} alt="profile_image" width="104px" />
            </div>
          )}
          {isFavorite && (
            <div style={{ position: 'absolute', top: '72%', right: -4 }}>
              <HeartBorderedIcon />
            </div>
          )}
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 20,
          }}
        >
          <div style={{ marginLeft: 20 }}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginTop: 15,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <b
                  style={{
                    fontSize: 20,
                    color: Colors.neutral_600,
                    fontFamily: 'Nunito',
                    margin: '0px 7px 0px 0px',
                  }}
                >
                  {`${candidate?.first_name} ${candidate?.last_name}`}
                </b>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <img
                    src={ratingIcon}
                    alt="green"
                    style={{
                      width: 20,
                      height: 20,
                      margin: '0px 5px 3px 0px',
                    }}
                  />
                  <b
                    style={{
                      fontSize: 16,
                      marginRight: 5,
                      color: Colors.neutral_600,
                      fontFamily: 'Nunito',
                    }}
                  >
                    {candidate?.average_rating?.toFixed(1) || '5.0'}
                  </b>
                </div>

                <div
                  style={{
                    display: 'flex',
                    alignSelf: 'center',
                    height: 16,
                    marginLeft: 8,
                    marginRight: 8,
                    border: `1px solid ${Colors.neutral_600}`,
                  }}
                />

                <div
                  className="global_font f-20"
                  style={{
                    marginLeft: 4,
                    display: 'flex',
                    gap: 8,
                    color: Colors.secondary_500,
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    alignItems: 'center',
                  }}
                  onClick={() =>
                    history.push(`/dashboard/candidate/${jobId}`, {
                      candidateId: candidate?.id,
                    })
                  }
                >
                  Profile
                  <img src={ChevronRight} alt="arrow" style={{ width: 16, height: 16 }} />
                </div>
              </div>
            </div>

            <div
              style={{
                fontFamily: 'Nunito',
                fontSize: 16,
                color: Colors.neutral_600,
              }}
            >
              Drivers License Verified
            </div>

            <div
              style={{
                fontFamily: 'Nunito',
                fontSize: 16,
                color: Colors.neutral_600,
              }}
            >
              {candidate?.license?.subProfession ?? candidate?.license?.profession} Credentials
              Verified
            </div>
            <CompanyMatchLabel professional={candidate} />
          </div>
        </div>
      </div>
      <ConfirmOrDecline candidateId={candidate?.id} notesForTemp={notesForTemp} />
    </div>
  </div>
));
