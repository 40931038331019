import '../themes/global.scss';
import '../themes/confirm_hyg.scss';

import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { Colors } from '../themes/colors';
import TrashIcon from '../images/icons/Trash';
import ArrowRightIcon from '../images/icons/ArrowRight';

export default ({ isLoading, deleteAll, selectedJobs, deleteOneAlert }) => {
  const allAlerts = useSelector((state) => state.alerts.allAlerts);

  if (!isLoading && allAlerts.length === 0) {
    return (
      <div
        style={{
          width: '95%',
          margin: 40,
          marginLeft: 20,
        }}
      >
        <h2
          className="global_font"
          style={{
            fontSize: 16,
            color: Colors.neutral_500,
            fontWeight: 'normal',
            textAlign: 'center',
          }}
        >
          You have no notifications.
        </h2>
      </div>
    );
  }

  return (
    <div
      style={{
        width: '95%',
        marginRight: 40,
        marginLeft: 20,
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        {!isLoading && (
          <h3
            className="global_font f-dark bold"
            style={{ color: Colors.secondary_500, cursor: 'pointer' }}
            onClick={() => deleteAll()}
          >
            Clear All
          </h3>
        )}
      </div>
      {allAlerts.map((alert, index) => (
        <div
          key={index}
          className="line_2"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              cursor: 'pointer',
              maxWidth: '80%',
            }}
            onClick={() => selectedJobs(alert)}
          >
            <p className="global_font f-dark bold f-16" style={{ marginRight: 5 }}>
              {alert.message}
            </p>
            <p className="global_font f-light f-16" style={{ marginTop: 0 }}>
              {' '}
              {moment(new Date(alert.created_at)).fromNow()}
            </p>
          </div>
          <div
            style={{
              width: 85,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <p
              className="global_font bold f-16"
              style={{ color: Colors.secondary_500, cursor: 'pointer', marginRight: 10 }}
              onClick={() => selectedJobs(alert)}
            >
              <ArrowRightIcon />
            </p>
            <p
              className="global_font bold f-16"
              style={{ color: Colors.error_500, cursor: 'pointer' }}
              onClick={() => deleteOneAlert(alert.id)}
            >
              <TrashIcon color={Colors.secondary_500} />
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};
