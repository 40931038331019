import { motion, AnimatePresence } from 'framer-motion';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import history from '../../../../history';
import { Colors } from '../../../../themes/colors';
import { useFixedWidth } from '../../hooks/useFixedWidth';
import { CollapsibleCandidateCard } from './CollapsibleCandidateCard';

export const CollapsibleCandidateSelection = ({ show }) => {
  const { jobId, candidateId } = useParams();
  const job = useSelector((state) => state.job.job);
  const parentRef = useRef(null);
  const fixedWidth = useFixedWidth(parentRef);

  const handleOnClick = (id) => {
    history.replace(`/dashboard/job/${jobId}/confirm/${id}`);
  };

  if (job?.multipleCounters?.length === 1) {
    return null;
  }

  return (
    <div
      ref={parentRef}
      style={{
        position: 'relative',
      }}
    >
      <AnimatePresence>
        {show && (
          <motion.div
            style={{
              width: fixedWidth,
              position: 'fixed',
              top: 64,
              zIndex: 10,
              paddingTop: 10,
              backgroundColor: Colors.white,
              boxShadow:
                '0px 3px 10px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.15)',
            }}
            initial={{ opacity: 0, y: 0 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 0 }}
            transition={{ duration: 0.3 }}
          >
            <div
              style={{
                position: 'sticky',
                top: 400,
                zIndex: 1000,
                backgroundColor: Colors.white,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  gap: 8,
                  position: 'sticky',
                  zIndex: 10,
                }}
              >
                {job?.multipleCounters?.slice(0, 5)?.map((item) => (
                  <CollapsibleCandidateCard
                    key={item.id}
                    item={item}
                    isSelected={candidateId === item?.candidate?.id}
                    onClick={() => handleOnClick(item?.candidate?.id)}
                  />
                ))}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
